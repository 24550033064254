import Cookies, { CookieSetOptions } from "universal-cookie";
import { cookies, endpoints } from "config";
import URI from "urijs";

const expiryInSeconds = 7 * 24 * 60 * 60;
const cookie = new Cookies();
export const ADMIN_COOKIE_NAME = "wellspace-admin-token";

export const COOKIE_OPTIONS: CookieSetOptions = {
  path: "/",
  domain: URI(endpoints.web || window.location.host).domain(),
  secure: cookies.secureCookie,
  maxAge: expiryInSeconds,
};

export const removeAdminCookie = (): void => {
  cookie.remove(ADMIN_COOKIE_NAME, COOKIE_OPTIONS);
  // Attempt to remove cookies that may have been set on the wrong domain
  cookie.remove(ADMIN_COOKIE_NAME);
};
