import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";
import { getBasePathWithAppId } from "helpers/routing";
import { IncomingMessage } from "http";

export type Config = AxiosRequestConfig;
export type Instance = AxiosInstance;
export type Response<T> = AxiosResponse<T>;

const defaultRequestConfig: Config = {
  withCredentials: true,
};

const axiosInstance: Instance = axios.create(defaultRequestConfig);

export const createSsrAxiosInstance: (
  req?: IncomingMessage,
  appId?: string,
  noCookies?: boolean
) => Instance = (req?: IncomingMessage, appId?: string, noCookies?: boolean) => {
  const cookie = req?.headers?.cookie;
  const instance = axios.create({
    withCredentials: true,
    baseURL: getBasePathWithAppId(appId),
    headers: {
      ...(cookie && !noCookies && { Cookie: cookie || "" }),
    },
  });
  return instance;
};

export default axiosInstance;
