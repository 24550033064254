import { isLoggedIn } from "helpers/user";
import { useEffect, useState } from "react";
import { UserContextInterface, User } from "types/user";
import { removeAdminCookie } from "services/user";
import { logout as authLogout } from "services/auth";
import { VendorConfigType } from "types/vendorConfig";
import { useCurrentAppId } from "hooks/useCurrentAppId";
import { useRouter } from "next/router";

export function useUserProvider(userParam?: User): {
  userContextValue: UserContextInterface;
} {
  const router = useRouter();
  const [user, setUser] = useState<User | null>(
    userParam && isLoggedIn(userParam) ? userParam : null
  );
  const [currentVendorConfig, setCurrentVendorConfig] = useState<VendorConfigType>(null);

  useEffect(() => {
    setUser(userParam && isLoggedIn(userParam) ? userParam : null);
  }, [userParam]);

  /* Set current vendorConfig */
  const { appId: currentAppId } = useCurrentAppId();
  useEffect(() => {
    if (currentAppId) {
      setCurrentVendorConfig(
        (user?.vendorConfigs || []).find(({ appId }) => appId === currentAppId) || null
      );
    } else {
      setCurrentVendorConfig(null);
    }
  }, [user, currentAppId]);

  const logout = async () => {
    try {
      await authLogout();
    } catch (e) {
      /* Do we need to handle it though? */
      console.log("logout error");
    } finally {
      removeAdminCookie();
      setUser(null);
      router.push("/sign-in");
    }
  };

  const userContextValue: UserContextInterface = {
    user,
    logout,
    setUser,
    currentVendorConfig,
  };

  return { userContextValue };
}
