import { transformQueryParamToString } from "helpers/routing";
import { useRouter } from "next/router";

export function useCurrentAppId(): {
  appId: string;
  hrefPrefix: string;
  asPrefix: string;
} {
  const router = useRouter();
  const appId = transformQueryParamToString(router?.query?.appId);
  const hrefPrefix = `/app-id/[appId]`;
  const asPrefix = `/app-id/${appId}`;

  return { appId, hrefPrefix, asPrefix };
}
