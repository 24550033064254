import api from "services/api";
import { LoginDTO, User } from "types/user";
import { BackendResponseWithData } from "types/response";
import { auth as authRoutes } from "constants/serviceRoutes";
import { endpoints } from "config";
import { AxiosInstance as AxiosInstanceType } from "axios";

export const login = async (loginData: LoginDTO): Promise<BackendResponseWithData<User>> => {
  const url = authRoutes.login;

  const { data } = await api.post(url, loginData, {
    headers: {
      "Pep-Site-Referrer": document.location.href,
    },
    baseURL: endpoints.adminApi,
  });

  return data;
};

export const status = async (
  currentLocation?: string,
  customAxiosInstance?: AxiosInstanceType
): Promise<BackendResponseWithData<User>> => {
  const url = authRoutes.status;

  const { data } = await (customAxiosInstance || api).get(url, {
    headers: {
      "Pep-Site-Referrer": currentLocation || document.location.href,
    },
    baseURL: endpoints.adminApi,
  });

  return data;
};

export const logout = async (): Promise<BackendResponseWithData<null>> => {
  const url = authRoutes.logout;

  const { data } = await api.post(
    url,
    {},
    {
      headers: {
        "Pep-Site-Referrer": document.location.href,
      },
      baseURL: endpoints.adminApi,
    }
  );

  return data;
};
