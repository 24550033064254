import { HttpLink, ApolloClient, InMemoryCache, ApolloLink, DefaultOptions } from "@apollo/client";
import { getBaseGQLPathWithAppId, getBaseGQLPathWithAppIdFromWindow } from "helpers/routing";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { isLocalEnv } from "config";
import {
  logNonArrayGraphQLErrors,
  logServerNetworkError,
} from "@pepdirect/helpers/logAndCaptureInSentry";

/**
 * Link to set URI. If "uri" is passed in context, it is used
 * as an endpoint. if appId is passed, it will be used with "predefined"
 * url. Otherwise, it will try to determine appId from the window.
 */
export const setUriLink = setContext((_request, previousContext) => {
  const { appId, uri } = previousContext;
  if (uri) {
    return { ...previousContext, uri };
  }
  const uriFromAppId = appId ? getBaseGQLPathWithAppId(appId) : getBaseGQLPathWithAppIdFromWindow();
  return { ...previousContext, uri: uriFromAppId };
});

export const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  const { operationName } = operation;
  /*
    - expected errors from BE, returned as 200 status code
    - do not log to DataDog/Sentry; any major errors returned as 200 (e.g.
      Braintree payment failures) will have already been logged on BE and
      are technically BE errors anyway
  */
  if (graphQLErrors) {
    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach((e) => {
        const { message, extensions } = e;
        // console.warn only in dev for debugging
        if (isLocalEnv) {
          const errorMessage =
            "[GraphQL 200 error]: " +
            `message: "${message}", ` +
            `operation name: ${operationName}` +
            `${extensions ? `, extensions: ${JSON.stringify(extensions, null, 2)}` : ""}`;
          console.warn(errorMessage);
        }
      });
    } else {
      logNonArrayGraphQLErrors({ graphQLErrors, operationName, response });
    }
  }

  // unexpected errors from BE, returned as 4-5xx status code
  if (networkError) {
    logServerNetworkError(networkError, operationName);
  }
});

const link = ApolloLink.from([setUriLink, errorLink, new HttpLink({ credentials: "include" })]);

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache" as const,
  },
  query: {
    fetchPolicy: "no-cache" as const,
  },
  mutate: {
    fetchPolicy: "no-cache" as const,
  },
};

const client = new ApolloClient({
  credentials: "include",
  defaultOptions,
  cache: new InMemoryCache(),
  link,
});

export default client;
