// DUE TO ISSUE WITH NX https://github.com/nrwl/nx/issues/602
// MUST MANUALLY KEEP THIS FILE IN SYNC WITH libs/ui/src/lib/helpers/currency.ts

import numeral from "numeral";

const round = (value: number, decimals: number): number => {
  const number = Number(`${value}e${decimals}`);
  const roundedValue = Math.round(number);

  return Number(`${roundedValue}e-${decimals}`);
};

export const convertFromDollarsToCents = (dollars: number): number => {
  const cents = numeral(dollars).multiply(100).value();

  return round(cents, 0);
};
