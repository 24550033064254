/**
 * if we do export { ConsentBannerConfigInterface } from "./lib/consentBanner" we get the following error:
 *
 * Attempted import error: 'ConsentBannerConfigInterface' is not exported from './lib/consentBanner'.
 *
 * workaround: https://github.com/babel/babel/issues/8361#issuecomment-409232456
 */

// IMPORTANT UPDATE FOR COMPONENT EXPORTS
// Nx monorepo libraries exports all the components in them,
// so even if we import just button component,
// all library items are included into our production bundle.
// This issue will be fixed by adding path alias for every single
// component in tsconfig.base.json until upgrading to webpack v.5.

export {
  getCloudflareImageSrcSet,
  getCloudflareImageUrl,
  getCloudflareLoader,
} from "./lib/cloudflareImages";
export { addGitShaToWindow } from "./lib/gitSha";
export {
  logEvent,
  logPageView,
  logProductViewedEvent,
  logProductClickedEvent,
  logProductDetailBuyButtonClickEvent,
  logProductTileBuyButtonClickEvent,
  logCartView,
  logCartCheckoutButtonClickEvent,
  logCartLineItemQtyChangedEvent,
  logSignIn,
  logSignUp,
  logPopularBrandsClickEvent,
  logBestSellersClickEvent,
  logCategoriesClickEvent,
  logNavigationClickEvent,
} from "./lib/analyticsLogger";
export { useIsMobile } from "./lib/hooks/useIsMobile";
export { getSubtotal } from "./lib/cart";
export { hasAuthTokenInContext } from "./lib/routing";
export { convertFromDollarsToCents } from "./lib/currency";
export { isFeatureFlagEnabled } from "./lib/featureFlagEnabled";
export { isEmail } from "./lib/isEmail";
export {
  logClientSideError,
  logServerNetworkError,
  logWithMessageInSentry,
} from "./lib/logAndCaptureInSentry";
